.row {
	display: flex;
	flex-flow: row wrap;
}

.row--center {
	justify-content: center;
}

.row--button {
	margin: 2rem 0;
	justify-content: center;
}

.row--vertical-center {
	align-items: center;
}

@mixin make-col($number-of-cols, $gap: 0) {
	width: calc((100%/#{$number-of-cols}) - #{$gap});
	gap: $gap;
}

.row--3c {
	.col {
		@include make-col(3, 5rem);
	}
}
