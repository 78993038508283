// Display and position definitions
// --------------------------------
.flex {
	display: flex;
}

.inline-flex {
	display: inline-flex;
}

.block {
	display: block;
}

.inline {
	display: inline;
}

.inline-block {
	display: inline-block;
}

.table {
	display: table;
}

.hidden {
	display: none;
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}
// --------------------------------

// Text aligns
// --------------------------------
.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-uppercase {
	text-transform: uppercase;
}
// --------------------------------

// Animations
// --------------------------------
.trans {
	transition: all 300ms ease-in-out;
}
// --------------------------------

// Text sizes
// --------------------------------
.text-large {
	font-size: 125%;
}

.text-small {
	font-size: 75%;
}
// --------------------------------

// Margins
// --------------------------------
.mt-0 {
	margin-top: 0;
}

.mt-1 {
	margin-top: 1rem;
}

.mt-2 {
	margin-top: 2rem;
}

.mt-3 {
	margin-top: 3rem;
}

.mt-4 {
	margin-top: 4rem;
}

.mt-5 {
	margin-top: 5rem;
}

.mt-6 {
	margin-top: 6rem;
}

.mb-0 {
	margin-bottom: 0;
}

.mb-1 {
	margin-bottom: 1rem;
}

.mb-2 {
	margin-bottom: 2rem;
}

.mb-3 {
	margin-bottom: 3rem;
}

.mb-4 {
	margin-bottom: 4rem;
}

.mb-5 {
	margin-bottom: 5rem;
}

.blue {
	color: #0F66DC;
}
